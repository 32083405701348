<template>
  <div class="page">
    <img class="bg" src="@/assets/images/farming.png" alt="" />
    <div class="g-container" v-loading="loading">
      <div class="header">
        <div class="title">
          <div class="title-item current">重要论述</div>
          <div class="title-item" @click="$router.push('/farming/list/index')">目录</div>
        </div>
        <!-- <el-button style="margin-left: auto; background-color: #fa541c;" type="danger">新增</el-button> -->
      </div>
      <div v-if="list.length === 0" class="g-empty"></div>
      <div class="g-py-20">
        <waterfall ref="waterfall" :col="2" :gutterWidth="20" :data="list">
          <template>
            <div class="item" v-for="item in list" :key="item.id">
              <div class="content">{{ item.content }}</div>
              <div class="source">{{ item.source }}</div>
              <div class="item-footer">
                <div class="g-mr-auto"
                  >落实工作 <i class="active">{{ item.pass_actions_count }}</i> 次</div
                >
                <template v-if="item.user_id === userId">
                  <el-button type="text" icon="el-icon-delete" @click="onDelete(item)">删除</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="onUpdate(item)">编辑</el-button>
                </template>
                <el-button class="link" type="text" @click="onNav(item)"
                  >查看详情 <i class="el-icon-arrow-right"></i
                ></el-button>
              </div>
            </div>
          </template>
        </waterfall>
        <b-pagination :page="page" @change="fetchPage"></b-pagination>
      </div>
    </div>

    <form-view ref="form" @success="onRefresh"></form-view>
  </div>
</template>

<script>
import FormView from '../list/form.vue'
import { getDetailPage, deleteDetail } from '@/api/farming'
export default {
  components: {
    FormView,
  },
  data() {
    return {
      menuId: null,
      loading: false,
      list: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        this.menuId = id
        this.fetchPage(id)
      },
      immediate: true,
    },
  },
  methods: {
    fetchPage() {
      this.loading = true
      const params = {
        menu_id: this.menuId,
        page: this.page.current,
        examine_status: 'pass',
      }
      getDetailPage(params)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
          this.$nextTick(() => {
            this.$waterfall.forceUpdate()
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchPage()
    },
    onUpdate(record) {
      this.$refs.form.open({
        id: record.id,
        menu_id: record.menu_id,
        content: record.content,
        source: record.source,
        date: record.date,
      })
    },
    onDelete(record) {
      this.$confirm('确定删除当前项吗？', '提示', { type: 'warning' })
        .then(() => {
          return deleteDetail(record.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.$router.back()
        })
        .catch(() => {})
    },
    onNav(record) {
      this.$router.push({ path: '/farming/action/index', query: { menuId: record.menu_id, id: record.id } })
    },
  },
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  overflow: hidden auto;
  background-color: #ffe2d7;
}

.bg {
  width: 100%;
  height: auto;
}

.header {
  display: flex;
  align-items: center;

  .title {
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff;
    border-radius: 0 19px 19px 0;

    .title-item {
      width: 100px;
      line-height: 22px;
      color: #fff;
      text-align: center;
      background-color: #fa541c;
      border-radius: 0 19px 19px 0;
      box-shadow: 0 2px 3px 0 rgba(131, 25, 9, 0.27);

      &:not(:last-child) {
        width: 120px;
        margin-left: -20px;
      }

      &:hover:not(.current) {
        cursor: pointer;
        background-color: #e64b17;
      }

      &.current {
        color: #fa541c;
        background-color: #fff;
      }
    }
  }
}

.item {
  padding: 10px 10px 0 10px;
  margin: 16px 8px;
  font-size: 14px;
  line-height: 30px;
  color: #1f1f1f;
  background-color: #fff;
  border-radius: 10px;

  .content {
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
  }

  .source {
    margin-top: 15px;
    font-size: 12px;
    line-height: 20px;
    color: #858c96;
  }

  .item-footer {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 12px;
    color: #9a9da1;

    .active {
      color: #fa541c;
    }

    .link:hover {
      color: #858c96;
      cursor: pointer;
    }
  }
}
</style>
